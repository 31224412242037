import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import RichText from "~components/molecules/rich_text/rich_text";
import RscHubCardCoverImage from "~components/molecules/rsc_hb_card/rsc_hb_card_cover_image/rsc_hb_card_cover_image";
import RscHbCtaLink from "~components/molecules/rsc_hb_cta/rsc_hb_cta_link/rsc_hb_cta_link";
import RscHbInPageContactForm from "~components/molecules/rsc_hb_in_page_contact_form/rsc_hb_in_page_contact_form";
import RscHbPageHeader from "~components/molecules/rsc_hb_page_header/rsc_hb_page_header";
import FeatureGrid from "~components/organisms/feature_grid/feature_grid";
import LogoGrid from "~components/organisms/logo_grid/logo_grid";
import LinkWrapper from "~components/utils/link_wrapper/link_wrapper";
import { createAnchorLink } from "~utils/link_utils/createAnchorLink/createAnchorLink";
import usePageData from "~hooks/use_page_data/use_page_data";
import { STORYBLOK_ENTRY } from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";
import {
  containerStyle,
  coverImageWrapperStyle,
  twoColumnLayoutStyle,
  wrapperStyle,
} from "./template_landing_page_report.module.scss";

export default function LandingPageReport(props) {
  const { story, pageContext } = usePageData(props);

  const { subNavData, lang, breadcrumbsItems } = pageContext;

  const {
    cover,
    description: descriptionRichTextArray,
    formId,
    formSubtitle,
    featureGrid: featureGridBlokArray,
    buttonText,
    formTitle,
    logos: logosArray,
    subtitle,
    title,
  } = story;

  let path = "/reports/";
  let queryString = "";

  const [description] = descriptionRichTextArray || [];
  const [featureGrid] = featureGridBlokArray || [];
  const [logos] = logosArray || [];

  // ToDo: (AMG) [tech Debt] [Landing pages] Get page path from context instead of window.location

  if (typeof window !== "undefined") {
    path = window.location.pathname;
    path += path.endsWith("/") ? "" : "/";
    queryString = window.location.search;
    path = `${path}${queryString}`;
  }

  const anchorLink =
    typeof window !== "undefined" && createAnchorLink({ path, anchor: `form` });

  return (
    <TemplateGlobalEntry subNavData={subNavData} {...props} headerColor="blue">
      <div className={wrapperStyle}>
        <Contain small>
          <RscHbPageHeader
            breadcrumbsItems={breadcrumbsItems}
            title={title}
            description={subtitle}
          />
          {logos && <LogoGrid {...logos} />}

          <div className={twoColumnLayoutStyle}>
            <div className={containerStyle}>
              <RichText {...description} />
            </div>
            <div className={coverImageWrapperStyle}>
              <LinkWrapper link={anchorLink}>
                <RscHubCardCoverImage
                  hasCard
                  portrait
                  roundBottom
                  roundTop
                  cover={cover}
                />
              </LinkWrapper>
            </div>
          </div>

          {featureGrid && <FeatureGrid {...featureGrid} />}

          <RscHbCtaLink
            theme="blue"
            title={formTitle}
            text={formSubtitle}
            link={anchorLink}
            buttonText={buttonText}
          />
        </Contain>

        <RscHbInPageContactForm
          formId={formId}
          title={formTitle}
          subtitle={formSubtitle}
          lang={lang}
        />
      </div>
    </TemplateGlobalEntry>
  );
}

LandingPageReport.propTypes = {
  data: PropTypes.shape({
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

LandingPageReport.defaultProps = {
  pageContext: {
    language: "en",
    subNavData: [],
  },
};

// TODO: get page specific metadata here
export const query = graphql`
  fragment reportLandingPageStory on Query {
    story: storyblokEntry(id: { eq: $id }) {
      assets {
        ...asset
      }
      content
      full_slug
      group_id
      alternates {
        full_slug
      }
    }
  }

  query reportLandingPageQuery($id: String, $langRegex: String) {
    ...reportLandingPageStory
    ...global
  }
`;
